@import "./mixins";

body {
  margin: 0;
  overflow: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#grommet {
  height: 100vh;
  width: 100vw;
}

#info-pane {
  overflow-y: hidden;

  .section-box {
    min-height: 100vh;
    padding: 1rem;

    &:last-child {
      @include below(md) {
        margin-bottom: 10vh;
      }
    }
  }
}

#display-box {
  @include below(md) {
    min-height: 100vh;
  }
}

#sidebar-wrapper {
  @include below(md) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}